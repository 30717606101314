<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('apps-experts-bookingtimes-list', { id: $route.params.expertid})"
        @save="createItem"
        :title="currentItem.starttime + ' - ' + currentItem.endtime + cardTitle"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
          @submit.stop.prevent="handleSubmit(createItem)"
      >
        <b-card>
          <div class="mb-2">
            <b-form-checkbox
                v-model="currentItem.status"
                value="1"
                name="status"
                class="custom-control-success"
            >
              {{ $t('Active') }}
            </b-form-checkbox>
          </div>

          <hr/>

          <b-row>
            <col md="12" xl="12" class="mb-1 mt-1 ml-1"/>

            <b-form-group :label="$t('Choose a Weekday')">
              <b-form-select v-model="currentItem.weekday" :options="options"/>
              <small v-if="validate && Object.keys(validation).includes('weekday')" class="text-danger"
              >{{ $t(validation.weekday[0]) }}</small>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider
                  #default="validationContext"
                  :name="$t('Start Time')"
                  rules="required"
              >
                <b-form-group :label="$t('Start Time')">
                  <cleave
                      id="starttime"
                      v-model="currentItem.starttime"
                      class="form-control"
                      :raw="false"
                      :options="timeOption.starttime"
                      placeholder="hh:mm"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('starttime')" class="text-danger"
                  >{{ $t(validation.starttime[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <validation-provider
                  #default="validationContext"
                  :name="$t('End Time')"
                  rules="required"
              >
                <b-form-group :label="$t('End Time')">
                  <cleave
                      id="endtime"
                      v-model="currentItem.endtime"
                      :name="$t('endtime')"
                      class="form-control"
                      :raw="false"
                      :options="timeOption.endtime"
                      placeholder="hh:mm"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('endtime')" class="text-danger"
                  >{{ $t(validation.endtime[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-button
              variant="success"
              type="submit"
          >
            {{ $t('Save') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormRadioGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormSelect, BFormCheckbox, BCardText, BFormCheckboxGroup, BFormFile, BAvatar, BMedia,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted, mounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, alphaNum, email } from '@validations'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BFormSelect,
    Cleave,
    BCardActions,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      selected: null,
      options: [
        {
          value: 1,
          text: this.$i18n.t('Sunday')
        },
        {
          value: 2,
          text: this.$i18n.t('Monday')
        },
        {
          value: 3,
          text: this.$i18n.t('Tuesday')
        },
        {
          value: 4,
          text: this.$i18n.t('Wednesday')
        },
        {
          value: 5,
          text: this.$i18n.t('Thursday')
        },
        {
          value: 6,
          text: this.$i18n.t('Friday')
        },
        {
          value: 7,
          text: this.$i18n.t('Saturday')
        },
      ],

      currentItem: {
        status: 1,
        weekday: 2,
        starttime: '',
        endtime: '',
      },

      timeOption: {
        starttime: {
          time: true,
          timePattern: ['h', 'm'],
        },
        endtime: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },
      loaded: true,
      validation: {},
      validate: false,
      cardTitle: ""
    }
  },

  methods: {
    createItem() {
      let url = `/experts/${this.$route.params.expertid}/bookingtimes`
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false

            if (success) {
              this.helperAddItem(`/experts/${this.$route.params.expertid}/bookingtimes`, this.currentItem, this.$refs.refFormObserver, null, (response) => {
                this.$router.push(
                    {
                      name: 'apps-experts-bookingtimes-edit',
                      params: {
                        expertid: this.$route.params.expertid,
                        bookingtimeid: response.data.id
                      },
                    },
                    () => setTimeout(() => {
                      this.hToast('success', 'Success', 'Item saved')
                    })
                )
              })
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  },
  mounted() {
    this.$http.get(`/experts/${this.$route.params.expertid}`)
        .then(response => {
          this.cardTitle = " (" + response.data.firstname + " " +response.data.name + ")"
        })
  }
}
</script>

<style>
</style>
